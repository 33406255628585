import './home.scss'

export default function Home() {

  return (
    <div class="coming-soon">
      <div class="logo"></div>
      <div class="blob"><></></div>
      <b class="text" >COMING SOON</b>
    </div>
  );
}